.lineItem--loader {
  .ssc-line {
    height: 1rem;
  }

  .planOption__item__title {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    > .vc-icon {
      height: 1.25rem;
    }

    .ssc-line {
      display: inline-block;
    }
  }
}