@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.sumup {
  padding: 1rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;

  &__title {
    padding-bottom: 1rem;
    font-weight: 500; // to use Helvetica medium 
  }

  &__cta {
    background: #fff;
    margin-top: 1rem;
    padding: 1rem 0;
    border-top: 1px solid $vc-color-grey-light;
    bottom: 0;

    .vc-icon {
      width: 0.66rem;
    }

    &__total {
      font-weight: bold;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }

  &__voucher {
    margin-top: 1.5rem;
  }

  &__amountLoading {
    width: 40px;
  }
}

/* Sticky logic  

*
* on mobile :
*
** the cta part is sticky 
** to the bottom of the screen.
** when reaching its original place,
** it goes back to normal flow.
** to that puprose,
** sumup__cta--stickyMobile is applied in js

*
* on desktop :
*
** the wall summary block is sticky,
** to the top of its column section.
*/

.mainContent--bag .sumup__cta {
  position: sticky;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include respond-to($sm-down) {
  .sumup__cta--stickyMobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-height: 100vh;
    left: 0;
    z-index: $sideNav-depth;
    padding: 1rem 1.5rem;
    background-color: $vc-color-g1;
    animation: fadeIn 0.3s;

    button .vc-icon-arrow-head-up,
    button .vc-icon-arrow-head-down {
      display: inline-block;
      width: 0.66rem;
      margin-left: 0.5rem;
    }
  }

  .sumup__wrapper::before {
    opacity: 0;
    z-index: calc($sideNav-depth - 1);
    background-color: black;
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .sumup__wrapper.overlay::before {
    bottom: 0;
    opacity: 0.3;
    transition: opacity 0.5s;
  }
}

@include respond-to($md-up) {
  .sumup {
    border: none;
    padding: 3rem 0 0 3rem;
    top: 0;

    &__wrapper {
      position: sticky;
      top: 0;
    }

    &__cta {
      background: unset;
    }
  }

  .mainContent--bag .sumup__cta {
    position: unset;
  }
}