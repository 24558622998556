@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

// overload reactjs-popup
.popup-content {
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  padding: 0;
  color: $vc-color-primary;

  &[role='tooltip'] {
    padding: 1rem;
    position: relative;
    box-shadow: none;
    max-width: 13rem;
    font-size: 0.875rem;
    color: #fff;
    background: black;
    border: none;
    width: 100%;
    border-radius: 2px;
  }

  .popup-arrow {
    filter: none;
    stroke: black;
    color: black;
  }
}



.vc-tooltip-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
}

/* animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include respond-to($md-up) {
  .popup-content[role='tooltip'] {
    max-width: 17.2rem;
    width: 100%;
  }
}
