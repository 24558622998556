@import '@vestiaire/design-system/scss/_variables.scss';

.popinErrorPayment {
  text-align: center;

  &__logo {
    display: block;
    width: 12.5em;
    margin: 0 auto 2.5em auto;
  }

  &__icon.vc-icon {
    display: inline-block;
    color: $vc-color-error;
    width: 2.5em;
    height: 2.5em;
    margin-bottom: 1.5em;
  }

  &__title {
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 1em;
  }
  
  &__message {
    font-size: 1.125em;
    line-height: 1.6em;
    margin-bottom: 2.5em;
  }
}