@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';
@import '/src/assets/scss/variables.scss';

.header {
  border-bottom: 1px solid $vc-color-grey-light;
  line-height: 0;
  text-align: center;
  padding: 1rem 2.5rem;

  &__link {
    display: inline-block;

    svg:first-child {
      display: block;
      width: 12.5rem;
    }
  }

  &__secured {
    color: $vc-color-g60;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 1rem;
    font-weight: 400;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    .vc-icon-lock {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

@include respond-to($md-up) {
  .header {
    padding: 1.5rem;
    margin-bottom: unset;
    border-bottom: 1px solid $vc-color-grey-light;
    text-align: center;

    &__link {
      svg:first-child {
        width: 15rem;
      }
    }

    .innerContainer {
      position: relative;
      max-width: $max-width;
    }

    &__secured {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
    }
  }
}
