@import '@vestiaire/design-system/scss/_variables.scss';

.voucherList {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  .w-50 {
    width: 12rem;
  }

  .w-30 {
    width: 5rem;
  }
  &__discountText {
    font-size: 0.875rem;
    &__remove {
      color: $vc-color-black-light;
      text-decoration: underline;
      margin-left: 0.5rem;
    }
  }

  &__discountReduction {
    color: $vc-color-valid-accessible;
    white-space: nowrap;
  }
}
