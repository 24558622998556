@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.popup-content[role='dialog'] {
  overflow-x: scroll;
  max-height: calc(100vh - 3rem);
  width: calc(100vw - 3rem);
  margin: auto 1.5rem !important;
  padding: 1.5rem;

  &.mobileFullScreen-content {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    padding: 1rem;
    margin: auto !important;
  }
}

@include respond-to($md-up) {
  .popup-content[role='dialog'] {
    max-width: 37.5rem;
    width: 80%;
    height: auto;
    margin: auto !important;
    padding: 1.5rem;

    &.mobileFullScreen-content {
      width: 80%;
      height: auto;
      max-height: calc(100vh - 3rem);
      padding: 3.5rem 2.5rem 2.5rem;
    }

    .vc-dialog__closeBtn__icon {
      display: block;
      width: 0.875rem;
      height: 0.875rem;
      margin-left: 0;
    }
  }
}
