@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.orderPaymentMethods {
  &--currentVersion {
    margin: 0 1.5rem 1.5rem 1.5rem;
  }

  &__missingAddress {
    color: $vc-color-g60;
    font-size: 1rem;

    .vc-icon {
      display: inline-block;
      vertical-align: top;
      margin-top: 2px;
      margin-right: 0.125rem;
      width: 1.25rem;
    }
  }

  &--skeleton {
    .pmOption__logos__item {
      width: 1.25rem;
      height: 1.25rem;
    }
    .radioButton__label .ssc-line {
      margin-bottom: 0;
    }
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    color: $vc-color-valid-accessible;
  }
  to {
    opacity: 0;
    color: $vc-color-valid-light;
  }
}

@keyframes disapear {
  from {
    height: 2.5rem;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
  }
  to {
    height: 0;
    margin-bottom: 0;
    padding: 0 1rem;
  }
}

.tempInfoHiglight {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0.25rem;
  background: $vc-color-valid-light;
  color: $vc-color-valid-accessible;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  animation: fadeOut 2s ease-in-out 2s forwards, disapear 2s ease-in-out 2.5s forwards;
}

@include respond-to($md-up) {
  .orderPaymentMethods {
    margin: 0;
  }
}

