@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.subtotals {
  margin-bottom: 1.5rem;
  
  &__entry {
    gap: 1rem;
    margin-bottom: 0.25rem;

    .ssc-line {
      margin: 0.375rem 0;
    }

    &:first-child {
      margin-bottom: 1.5rem;
    }

    &__amount {
      .ssc-line {
        vertical-align: middle;
        min-width: 3rem;
        margin: 0.375rem 0;
      }
    }

    &__tooltip {
      &__icon {
        vertical-align: middle;
        transform: translateY(-1px);
        width: 1rem;
        height: 1rem;
        margin-left: 0.5rem;
        color: $vc-color-black-light;
      }
      &__link {
        text-decoration: underline;
      }
    }
  }
}