.spinner {
  width: 1.5rem;
  margin: 0 auto;

  svg {
    animation: rotate 1s infinite linear;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
  }
}
