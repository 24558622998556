@import '@vestiaire/design-system/scss/_variables.scss';

.PaymentTcAcceptance {
  margin-top: 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 17.64px;
  color: $vc-color-black-light;

  a {
    color: $vc-color-black-base;
  }
}