@import '@vestiaire/design-system/scss/_variables.scss';

.card {
  border: 1px solid $vc-color-g20;
  width: 100%;

  &__header {
    padding: 1rem 1.5rem;
    background-color: $vc-color-g5;
  }

  &__body {
    padding: 1.5rem;
  }
}
