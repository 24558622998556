@import '@vestiaire/design-system/scss/_variables.scss';

.planServices {
  font-size: 0.875rem;
  line-height: 1.25rem;

  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;

    &__title {
      color: $vc-color-black-light;
    }
  }

  .isFree {
    color: $vc-color-black-base;
  }
}
