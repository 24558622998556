@import '@vestiaire/design-system/scss/_variables.scss';

.radioButton {
  &__label {
    display: block;
    padding-left: 1.5rem;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
      box-sizing: border-box;
      display: block;
      content: '';
      width: 1rem;
      height: 1rem;
      border: 1px solid $vc-color-grey-dark;
      border-radius: 50%;
      background: white;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      display: none;
      width: 0.625rem;
      height: 0.625rem;
      background: $vc-color-primary;
      margin: 0 0.1876rem;
      border: 0;
    }
  }

  &__input:checked {
    + .radioButton__label:before {
      border-color: $vc-color-primary;
    }

    + .radioButton__label:after {
      display: block;
    }
  }
}
