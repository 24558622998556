@import '@vestiaire/design-system/scss/_variables.scss';

.pmOption {
  .radioButton__label {
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    border: 1px solid $vc-color-g30;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    margin-top: -1px;
    font-size: 0.875rem;

    &::before,
    &::after {
      left: 1rem;
    }
  }

  .radioButton__input:checked+.radioButton__label {
    background-color: $vc-color-g5;
    z-index: 1;
  }

  &__name {
    flex-grow: 1;
    white-space: nowrap;
  }

  &__logos {
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
    min-height: 1rem;
    height: 1.375rem;

    &__item {
      display: block;
      height: 100%;
      width: 100%; // to prevent safari bug
      max-width: 3.125rem;
      margin-left: 0.5rem;
      object-fit: contain;

      &:nth-child(n+4) {
        display: none;
      }

      &--paypal {
        max-width: 3.75rem;
      }

      &--clicktopay {
        max-width: 2rem;
      }
    }

    &.expanded {
      .pmOption__logos__item:nth-child(n+4) {
        display: inline-block;
      }
    }

    &__more {
      display: inline-block;
      margin-left: 0.5rem;
      border: 1px solid $vc-color-g20;
      background-color: #fff;
      color: $vc-color-g60;
      font-size: 0.75rem;
      line-height: 1.5rem;
      height: 1.5rem;
      padding: 0 0.5rem;
      white-space: nowrap;
      border-radius: 0.125rem;
      cursor: pointer;
    }
  }

  &__expdate {
    white-space: nowrap;
  }
}