@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.lineItem {
  border-bottom: 1px solid $vc-color-grey-light;
  margin-bottom: 1.5rem;
  overflow: hidden;
  padding-bottom: 1.5rem;

  &--currentVersion {
    padding: 1.5rem;

    &.lineItem:last-child {
      margin-bottom: 1.5rem;
    }
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &--deleted {
    animation: removed-item-animation 0.6s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
  }

  &--dimmed {
    .lineItem__container {
      opacity: 0.4;
    }
  }

  &__container {
    &__delete {
      text-decoration: underline;
    }

    &__unavailableSellerWarning {
      margin-top: 1rem;
      margin-bottom: 1rem;

      .vc-icon {
        height: 1rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.5rem;
      }
    }

    &__reservation {
      color: $vc-color-premium;
      background-color: rgba($vc-color-premium, 0.08);
      padding: 0.375rem 0.75rem;
      border-radius: 0.25rem;
      margin-top: 1rem;

      .vc-icon {
        height: 1rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.75rem;
      }
    }
  }
}

@include respond-to($md-up) {
  .lineItem {
    padding: 0 0 2.5rem 0;
    margin-bottom: 1.5rem;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

@include respond-to($lg-up) {
  .lineItem {
    margin-bottom: 2rem;
  }
}

@keyframes removed-item-animation {
  from {
    opacity: 1;
    transform: scale(1);
  }

  99% {
    opacity: 0;
    transform: scale(0);
    padding: 0;
  }

  100% {
    opacity: 0;
    padding: 0;
    height: 0;
  }
}
