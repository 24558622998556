
@import '@vestiaire/design-system/scss/_variables.scss';

.spl {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.75rem;
  line-height: 1.25rem;

  &.condensed {
    gap: 0.5rem;
  }
}
