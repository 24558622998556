@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';
@import '/src/assets/scss/variables.scss';

.mainContent {
  &__header {
    position: sticky;
    top: 0;
  }

  &__aside {
    border-top: 0.5rem solid $vc-color-g5;
  }
}

@include respond-to($md-up) {
  .mainContent {
    width: 100%;
    max-width: calc($max-width + 2 * $outter-padding);
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    margin: 0 auto;
    padding: 0 $outter-padding;

    &__content {
      flex: 1;
      margin-right: 3rem;
      margin-bottom: 4.5rem;
    }

    &__aside {
      align-self: stretch;
      background-color: $vc-color-g1;
      width: 38.7%;
      min-width: 38.7%;
      max-width: 38.7%;
      position: relative;
      border-top: none;

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: calc(100vw - #{$max-width} / 2);
        left: 100%;
        top: 0;
        bottom: 0;
        background-color: $vc-color-g1;
      }
    }
  }
}

@include respond-to($lg-up) {
  .mainContent {
    &__aside {
      margin-right: 0;
    }
  }
}