@import '@vestiaire/design-system/scss/_variables.scss';

.product {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  flex-grow: 1;
  overflow: hidden;

  &__main {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    min-width: 0;
  }

  &__title {
    min-width: 0;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &__brand {
      text-transform: uppercase;
    }
  }

  &__amount {
    flex-shrink: 0;
    color: $vc-color-black-base;
  }

  &__priceDetails {
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    color: $vc-color-g60;
    width: 100%;

    &__item {
      display: flex;
      flex-flow: row nowrap;
      gap: 1rem;
      justify-content: space-between;

      &__amount {
        flex-shrink: 0;
      }
    }
  }
}