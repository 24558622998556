.sumup--skeleton {
  .sumup__title {
    height: 2.5rem;
  }
  .sumup__cta__total {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .ssc-line {
      height: 1rem;
    }
  }
  .PaymentTcAcceptance {
    .ssc-line {
      height: 10.1px;
      padding-bottom: 0;
      margin-bottom: 10.05px; 
    }
  }
}