@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.voucherForm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
  overflow: hidden;

  &__label {
    margin-bottom: 0.5rem !important;
    font-size: unset;
    color: $vc-color-primary;
    min-width: 10rem;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;

    input {
      width: auto;
      flex: auto;
      border-radius: 0.125rem;
      border-color: $vc-color-g20;
      transition: all 0.3s;
    }

    .vc-formUnit {
      width: 100%;
      min-width: 100%;
      overflow: hidden;
      margin-bottom: 0;
      margin-right: 0.75rem;

      &.error {
        .vc-inputText {
          padding-right: 0;
        }
      }

      &.showCTA {
        min-width: 0;

        .vc-inputText {
          width: 100%;
          padding-right: 0;
        }
      }
    }

    .ssc {
      height: 3rem;
    }
  }

  &__errorMsg {
    color: $vc-color-system-error50;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.25rem;
    line-height: 1rem;
    margin-top: 0.25rem;

    .vc-icon {
      min-width: 1rem;
      width: 1rem;
      height: 1rem;
    }
  }

  &__hint {
    color: $vc-color-g60;
    line-height: 1.125rem;
  }

  .ssc-line {
    height: auto;
    border-top: none;
    border-bottom: none;
    display: inline-flex;
    width: auto;
  }
}

@include respond-to($md-up) {
  .voucherForm {
    justify-content: unset;
    align-items: baseline;

    &__label {
      color: $vc-color-primary;
      flex-grow: 1;
    }

    &__body {
      flex-direction: row;
      align-items: center;
      width: inherit;
    }
  }
}