.paymentButtonsWrapper {
  min-height: 3rem;
}

.paymentButton {
  position: relative;
  min-height: 3rem;

  &__spinner {
    display: inline-block;
    margin: 0 1rem 0 0;
  }
}
