@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.lineItem__seller {
  border-bottom: 1px solid $vc-color-grey-light;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  &__header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    &__description {
      display: flex;
      flex-flow: row wrap;
      flex: 1;
      align-items: center;
    }

    &__picture {
      background-color: $vc-color-grey-light;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      margin-right: 0.75rem;
      object-fit: cover;
    }

    &__name {
      font-weight: bold;

      &__pro {
        color: $vc-color-info;
      }
    }

    .vc-badge {
      white-space: nowrap;
      margin-top: 0.3125rem;
    }

    &__seemore {
      margin-left: auto;

      .vc-icon {
        display: inline-block;
        width: 0.625rem;
        margin-left: 0.3rem;
        vertical-align: middle;
      }
    }
  }

  &__content {
    display: flex;
    flex-flow: row wrap;
    color: $vc-color-black-light;
    padding-top: 0.75rem;
    margin: -0.125rem;

    &__item {
      padding: 0.125rem;
      width: 50%;

      &__date {
        text-transform: capitalize;
      }

      .vc-icon {
        display: inline-block;
        vertical-align: middle;
        width: 0.6875rem;
      }
    }
  }
}

@include respond-to($md-up) {
  .lineItem__seller {
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;

    &__header__picture {
      width: 3rem;
      height: 3rem;
    }

    &__content__item {
      width: 33.33%;

      &:nth-child(2) {
        //line-break
        width: 66.66%;
      }
    }
  }
}
