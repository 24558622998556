@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

// TO DO : dipatch in sub sections and clean

.plans {
  padding: 0.25rem 0;
}

.planOption {
  padding: 0.75rem;

  .radioButton__label {
    padding-left: 2rem;

    &::before,
    &::after {
      top: 0.875rem;
    }
  }

  &__item {
    display: flex;

    &__title {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0.25rem;

      > .vc-icon {
        display: inline-block;
        width: 1.25rem;
        margin-right: 0.125rem;
        vertical-align: middle;
        margin-top: -3px;
      }
    }

    &--disabled {
      opacity: 0.4;
    }
  }
}
