@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.deliveryOptions {
  font-size: 0.875rem;

  &--currentVersion {
    padding: 0 1.5rem;
  }

  &__entry {
    border-bottom-width: 0;

    &:last-child {
      border-bottom-width: 1px;
    }

    .card__header {
      padding: 0;
    }
  }

  &__title {
    padding: 1rem 1.5rem;
    font-size: 1rem;
    font-weight: 700;
  }

  &__label {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;

    &__price {
      font-weight: normal;
      font-size: 0.875rem;

      &--skeleton {
        width: 2.5rem;
        height: 1rem;
      }
    }

    .radioButton__label {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.5rem 1rem 3rem;

      &::before,
      &::after {
        left: 1.5rem;
      }
    }
  }

  &--skeleton {
    .card__header .ssc-line {
      margin: 0.375rem 0;
    }
    .deliveryAddress__current {
      &__name {
        min-height: 1.3125rem;
      }
      .ssc-line {
        margin-bottom: 9px;
      }
    }
    .deliveryAddress__actions__cta {
      display: inline-block;
    }
  }
}

@include respond-to($sm-down) {
  // only applies to new users without delivery address on mobile
  .deliveryOptions + .checkoutSection__title {
    margin-top: 1.5rem;
  }
}

@include respond-to($md-up) {
  .deliveryOptions {
    padding: 0;
  }
}
