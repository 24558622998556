.subtotals {
  &__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 0 1rem;
  }

  &__amount {
    white-space: nowrap;
  }

  &__content {
    cursor: default;
  }

  &__detailsWrapper {

    &.noToggle .subtotals__header {
      pointer-events: none;
    }

    &:hover:not(.noToggle) {
      cursor: pointer;
    }

    .subtotals__title .vc-icon-arrow-head-down {
      display: inline-block;
      width: 0.5rem;
      height: auto;
      margin-left: 0.55rem;
    }

    &[open] .subtotals__title > .vc-icon-arrow-head-down {
      rotate: -180deg;
    }
  }
  
}