@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.billingAddress {
  font-size: 0.875rem;
  margin-top: 1rem;

  &--currentVersion {
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.875rem;
  }

  &__sameAsDelivery {
    line-height: 1.125rem;

    .vc-form-checkboxLabel {
      margin: 1px 0;
      padding-left: 1.625rem;
    }
  }

  &__card {
    border: 1px solid $vc-color-g20;
    padding: 1rem 1.5rem;
    margin-top: 1rem;
  }

  &__current {
    margin-bottom: 1rem;

    &__name {
      font-weight: bold;
      font-display: inline-block;
      margin-bottom: 0.125rem;
    }
  }

  &__actions {
    &__cta {
      cursor: pointer;
      text-decoration: underline;

      &:first-child {
        position: relative;
        margin-right: 0.5rem;

        &:after {
          display: block;
          content: '';
          height: 100%;
          width: 1px;
          position: absolute;
          right: -0.5rem;
          top: 0;
          background-color: #000;
        }
      }

      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }
}

@include respond-to($md-up) {
  .billingAddress {
    padding: 0;

    &__card {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }

    &__current {
      margin-bottom: 0;
    }
  }
}
