@import '@vestiaire/design-system/scss/_variables.scss';

body {
  font-family: $vc-font-body, Arial, sans-serif;
}
details > summary {
  list-style: none;
}
details summary::-webkit-details-marker {
  display: none;
}

.vc-checkout--overlay {
  position: relative;

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($color: $vc-color-black-base, $alpha: 0.5);
    z-index: $modal-depth;
  }
}

.ssc--btn {
  position: relative;
  overflow: hidden;
  mask-image: radial-gradient(white, #000);
  display: block;
  width: 100%;
  background-color: #ebebeb;
  border-color: #ebebeb; 

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(245, 246, 248, .8), rgba(255, 255, 255, 0));
    transform: translateX(-100%);
    animation: ssc-loading 1.3s infinite;
  }

  &:hover {
    background-color: #ebebeb;
    border-color: #ebebeb;
  }
}

.ssc-line {
  text-decoration: none !important;
}