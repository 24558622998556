@import '@vestiaire/design-system/scss/_variables.scss';
// we have handled the loading styles inside components so for first load we should load them here to make sure that we have bundled them inside the main js file.
// TODO: extract loading styles to separate components.
@import 'src/pages/Checkout/CheckoutFallback/CheckoutFallback.scss';

.vc-checkout {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  flex-direction: column;
}

.divider {
  border: 1px solid $vc-color-grey-light;
}
