@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.productItem {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  line-height: 1.5;

  &__linkWrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__picture {
    display: block;
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 0.5rem;
  }

  &__description {
    color: $vc-color-g60;
    font-size: 0.875rem;
  }

  &__brand {
    font-weight: bold;
    line-height: 1.3125;
    color: $vc-color-primary;
    text-transform: uppercase;
  }

  &__price {
    margin-left: auto;
    font-weight: bold;
    white-space: nowrap;

    &__total {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.25rem;
    }

    &__info {
      width: 1rem;
      height: 1rem;
      color: $vc-color-g60;
    }
  }
}

@include respond-to($lg-up) {
  .productItem {
    &__picture {
      width: 6rem;
      height: 6rem;
      margin-right: 1rem;
    }

    &__price {
      line-height: 1.4375rem;
    }
  }
}
