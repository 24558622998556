@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.billingAddressSkeleton {
  .billingAddress__actions .ssc-line {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }
}

@include respond-to($md-up) {
  .billingAddressSkeleton {
    .billingAddress__current {
      width: 50%;
    }
    .billingAddress__actions {
      min-width: 40%;
    }
  }
}
