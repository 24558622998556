@import '@vestiaire/design-system/scss/_variables.scss';

.SubtotalDetails {
  padding-left: 0.5rem;

  &__item {
    display: flex;
    flex-flow: row nowrap;
    gap: 0 1rem;
    justify-content: space-between;
    font-size: 0.875rem;
    color: $vc-color-g60;

    &__desc {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      .brand {
        text-transform: uppercase;
      }
    }

    &__amount {
      white-space: nowrap;
    }
  }

}