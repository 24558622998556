@import '@vestiaire/design-system/scss/_variables.scss';
@import '@vestiaire/design-system/scss/_mixins.scss';

.checkoutSection {
  &__title {
    padding: 1.5rem 1.5rem 1.25rem 1.5rem;
    font-weight: 500;
    font-size: 1.25rem;
    border-top: solid 0.5rem $vc-color-g5;

    &:first-of-type {
      padding-bottom: 0.5rem;
    }
  }
}

@include respond-to($md-up) {
  .checkoutSection__title {
    font-size: 1.5rem;
    padding: 2rem 0 1.5rem;
    border-top: 1px solid black;
    margin-top: 3rem;

    &:first-of-type {
      border-top: 0;
      margin-top: 1rem;
    }
  }
}
